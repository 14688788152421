exports.components = {
  "component---src-new-version-templates-about-page-about-page-tsx": () => import("./../../../src/new-version/templates/aboutPage/aboutPage.tsx" /* webpackChunkName: "component---src-new-version-templates-about-page-about-page-tsx" */),
  "component---src-new-version-templates-contact-page-contact-page-tsx": () => import("./../../../src/new-version/templates/contactPage/contactPage.tsx" /* webpackChunkName: "component---src-new-version-templates-contact-page-contact-page-tsx" */),
  "component---src-new-version-templates-industry-page-industry-page-tsx": () => import("./../../../src/new-version/templates/industryPage/industryPage.tsx" /* webpackChunkName: "component---src-new-version-templates-industry-page-industry-page-tsx" */),
  "component---src-new-version-templates-landing-page-landing-page-tsx": () => import("./../../../src/new-version/templates/landingPage/landingPage.tsx" /* webpackChunkName: "component---src-new-version-templates-landing-page-landing-page-tsx" */),
  "component---src-new-version-templates-portfolio-archive-portfolio-archive-tsx": () => import("./../../../src/new-version/templates/portfolioArchive/portfolioArchive.tsx" /* webpackChunkName: "component---src-new-version-templates-portfolio-archive-portfolio-archive-tsx" */),
  "component---src-new-version-templates-portfolio-categories-portfolio-categories-tsx": () => import("./../../../src/new-version/templates/portfolioCategories/portfolioCategories.tsx" /* webpackChunkName: "component---src-new-version-templates-portfolio-categories-portfolio-categories-tsx" */),
  "component---src-new-version-templates-portfolio-page-portfolio-page-tsx": () => import("./../../../src/new-version/templates/portfolioPage/portfolioPage.tsx" /* webpackChunkName: "component---src-new-version-templates-portfolio-page-portfolio-page-tsx" */),
  "component---src-new-version-templates-pre-built-academic-lms-page-pre-built-academic-lms-page-tsx": () => import("./../../../src/new-version/templates/preBuiltAcademicLmsPage/preBuiltAcademicLmsPage.tsx" /* webpackChunkName: "component---src-new-version-templates-pre-built-academic-lms-page-pre-built-academic-lms-page-tsx" */),
  "component---src-new-version-templates-pre-built-lms-page-pre-built-lms-page-tsx": () => import("./../../../src/new-version/templates/preBuiltLmsPage/preBuiltLmsPage.tsx" /* webpackChunkName: "component---src-new-version-templates-pre-built-lms-page-pre-built-lms-page-tsx" */),
  "component---src-new-version-templates-pricing-archive-pricing-archive-tsx": () => import("./../../../src/new-version/templates/pricingArchive/pricingArchive.tsx" /* webpackChunkName: "component---src-new-version-templates-pricing-archive-pricing-archive-tsx" */),
  "component---src-new-version-templates-pricing-page-pricing-page-tsx": () => import("./../../../src/new-version/templates/pricingPage/pricingPage.tsx" /* webpackChunkName: "component---src-new-version-templates-pricing-page-pricing-page-tsx" */),
  "component---src-new-version-templates-privacy-policy-page-privacy-policy-page-tsx": () => import("./../../../src/new-version/templates/privacyPolicyPage/privacyPolicyPage.tsx" /* webpackChunkName: "component---src-new-version-templates-privacy-policy-page-privacy-policy-page-tsx" */),
  "component---src-new-version-templates-service-page-service-page-tsx": () => import("./../../../src/new-version/templates/servicePage/servicePage.tsx" /* webpackChunkName: "component---src-new-version-templates-service-page-service-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dach-tsx": () => import("./../../../src/pages/dach.tsx" /* webpackChunkName: "component---src-pages-dach-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-uae-tsx": () => import("./../../../src/pages/uae.tsx" /* webpackChunkName: "component---src-pages-uae-tsx" */),
  "component---src-pages-uk-tsx": () => import("./../../../src/pages/uk.tsx" /* webpackChunkName: "component---src-pages-uk-tsx" */),
  "component---src-templates-ai-for-education-new-page-ai-for-education-new-page-tsx": () => import("./../../../src/templates/aiForEducationNewPage/aiForEducationNewPage.tsx" /* webpackChunkName: "component---src-templates-ai-for-education-new-page-ai-for-education-new-page-tsx" */),
  "component---src-templates-blog-archive-blog-archive-tsx": () => import("./../../../src/templates/blogArchive/blogArchive.tsx" /* webpackChunkName: "component---src-templates-blog-archive-blog-archive-tsx" */),
  "component---src-templates-blog-article-blog-article-tsx": () => import("./../../../src/templates/blogArticle/blogArticle.tsx" /* webpackChunkName: "component---src-templates-blog-article-blog-article-tsx" */),
  "component---src-templates-blog-tags-archive-blog-tags-archive-tsx": () => import("./../../../src/templates/blogTagsArchive/blogTagsArchive.tsx" /* webpackChunkName: "component---src-templates-blog-tags-archive-blog-tags-archive-tsx" */),
  "component---src-templates-career-page-career-page-tsx": () => import("./../../../src/templates/careerPage/careerPage.tsx" /* webpackChunkName: "component---src-templates-career-page-career-page-tsx" */),
  "component---src-templates-industry-page-industry-page-tsx": () => import("./../../../src/templates/industryPage/industryPage.tsx" /* webpackChunkName: "component---src-templates-industry-page-industry-page-tsx" */),
  "component---src-templates-insight-archive-insight-archive-tsx": () => import("./../../../src/templates/insightArchive/insightArchive.tsx" /* webpackChunkName: "component---src-templates-insight-archive-insight-archive-tsx" */),
  "component---src-templates-insight-article-insight-article-tsx": () => import("./../../../src/templates/insightArticle/insightArticle.tsx" /* webpackChunkName: "component---src-templates-insight-article-insight-article-tsx" */),
  "component---src-templates-landing-page-landing-page-tsx": () => import("./../../../src/templates/landingPage/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-tsx" */),
  "component---src-templates-parent-page-parent-page-tsx": () => import("./../../../src/templates/parentPage/parentPage.tsx" /* webpackChunkName: "component---src-templates-parent-page-parent-page-tsx" */),
  "component---src-templates-partnership-page-partnership-page-tsx": () => import("./../../../src/templates/partnershipPage/partnershipPage.tsx" /* webpackChunkName: "component---src-templates-partnership-page-partnership-page-tsx" */),
  "component---src-templates-privacy-policy-page-privacy-policy-page-tsx": () => import("./../../../src/templates/privacyPolicyPage/privacyPolicyPage.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-privacy-policy-page-tsx" */),
  "component---src-templates-qa-policy-page-qa-policy-page-tsx": () => import("./../../../src/templates/qaPolicyPage/qaPolicyPage.tsx" /* webpackChunkName: "component---src-templates-qa-policy-page-qa-policy-page-tsx" */),
  "component---src-templates-reviews-page-reviews-page-tsx": () => import("./../../../src/templates/reviewsPage/reviewsPage.tsx" /* webpackChunkName: "component---src-templates-reviews-page-reviews-page-tsx" */),
  "component---src-templates-search-results-page-search-results-page-tsx": () => import("./../../../src/templates/searchResultsPage/searchResultsPage.tsx" /* webpackChunkName: "component---src-templates-search-results-page-search-results-page-tsx" */),
  "component---src-templates-service-page-service-page-tsx": () => import("./../../../src/templates/servicePage/servicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-service-page-tsx" */),
  "component---src-templates-technology-page-technology-page-tsx": () => import("./../../../src/templates/technologyPage/technologyPage.tsx" /* webpackChunkName: "component---src-templates-technology-page-technology-page-tsx" */),
  "component---src-templates-vacancy-page-vacancy-page-tsx": () => import("./../../../src/templates/vacancyPage/vacancyPage.tsx" /* webpackChunkName: "component---src-templates-vacancy-page-vacancy-page-tsx" */)
}

